@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* Usage: font-family: tropikal; */
@font-face {
  font-family: tropikal;
  src: url(assets/fonts/tropikal-bold.otf);
}


h1 {
  font-size: 2.45rem;
}

h2 {
  font-size: 1.95rem;
}

h3 {
  font-size: 1.56rem;
}

h4 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Noto Sans JP', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

/* TODO: use jp class for body if language is in japanese */
body .jp {
  line-height: 1.6;
}

.font-tropikal {
  font-family: 'tropikal', 'Lato', 'Noto Sans JP', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


.custom-hero-mobile {
  background-image: url('assets/images/homeImg/hero-image.jpg');
  background-size: cover;
  background-position: center bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Input field effect */
.input-field {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-bottom: 4px solid transparent;
  transition: border-color 0.2s ease-in-out;
  border-radius: 8px;
}

.input-field:focus {
  outline-style: none;
  box-shadow: none;
  outline: none;
  border-bottom-color: #A7D747;
}

.aspect-ratio-square {
  width: 100%; 
  height: auto;
  aspect-ratio: 1 / 1; 
}



/* NAV ANIMATION */
/* .nav-in {
  animation: NavMenuIn .3s forwards;
}
.nav-out {
  animation: NavMenuOut .3s forwards;
}

@keyframes NavMenuIn{
  0%{
    display: grid;
    opacity: 0;
    transform: translateY(-50%) scaleY(0);
  }
  100%{
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
}

@keyframes NavMenuOut{
  0%{
    display: grid;
    opacity: 1;
    transform: translateY(0) scaleY(1);
  }
  100%{
    display: none;
    opacity: 0;
    transform: translateY(-50%) scaleY(0);
  }
} */